// eslint-disable-next-line
export var dictionary_fr = {
  'util': {
    'unEscapeTag': '{{- tag}}'
  },
  'language': {
    'en': '🇨🇦 EN',
    'fr': '🇫🇷 FR',
    'cns': '🇨🇳 中文'
  },
  'flag': '🇨🇦',
  'head': {
    'title': 'AntsLabor PropTech',
    'slogan': 'Ménage automatisé',
    'company': 'Fabriqué avec ☕️ , 🎵 & 💗 par Ants Labor Ltd.'
  },
  'logo': {
    'link': '/static/logo-short.svg',
    'link-mobile': '/static/logo-circle.png',
    'link-sidebar': '/static/logo-short.svg',
    'link-white': '/static/logo-white.png'
  },
  'general': {
    'welcome': 'Bienvenue',
    'hello': '👋 Bonjour',
    'date': '📆 Date',
    'language': '🌍 Langue: ',
    'currency': '💱 Monnaie: ',
    'success': 'Succès 🎉',
    'ok': 'D\'accord',
    'yes': '✔ Oui',
    'no': '✖ Non',
    'edit': 'Éditer', // 📝
    'hide_all': 'Cacher tout',
    'remove': '❌',
    'delete': 'Effacer',
    'archive': 'Archiver',
    'continue': 'Continuez',
    'resume': 'Reprendre',
    'return': 'Arrière',
    'save': 'Conserver 💾',
    'refund': 'Remboursement',
    'print': 'Imprimer 🖨',
    'send': 'Envoyer 🚢',
    'show': 'Spectacle 💻',
    'export': 'Exportation 📦',
    'upload': '📤 Télécharger',
    'choose_file': '🗂 Choisissez la photo',
    'free_trial': 'Essai gratuit',
    'signup': 'S\'inscrire',
    'login': 'Connexion',
    'signin_signup': 'S\'identifier / S\'inscrire',
    'home': 'Accueil 🏡',
    'change_password': 'Changer le mot de passe',
    'reset_password': 'Réinitialiser le mot de passe!',
    'forgot_password': 'Mot de passe oublié?',
    'logout': 'Se déconnecter',
    'confirmation': 'la ratification',
    'are_you_sure': 'Es-tu sûr?',
    'alert': 'Alerte ❗️',
    'enter': 'Entrer',
    'submit': 'Soumettre', // 👍
    'abort': 'Avorter', // ⛔
    'confirm': 'Confirmer', // ✅
    'cancel': 'Annuler', // ❎
    'username': 'Nom d\'utilisateur 🐣',
    'password': 'le mot de passe 🔒',
    'birthday': 'Anniversaire 🎂',
    'search': 'Recherche par adresse',
    'copy': 'Copie',
    'join-us': 'Rejoignez-nous',
    'details': 'Détails',
    'manual': 'Comment ça fonctionne',
    'pitch': 'Pitch Deck',
    'page': 'Page',
    'share': 'Partager',
    'view_more': 'Voir plus de détails'
  },
  'error': {
    'invalid_input': 'Invalid Input',
    'try_again': 'Please try again.',
    'is_taken': ' has been taken.',
    'unexpected_amount': 'Unexpected Payment Amount',
    'api_not_found': 'API Not Found.',
    'data_not_found': 'Data Not Found.',
    'user_not_found': 'I do not recognize this user.',
    'user_already_exist': 'Email already exists.',
    'missing_required_element': 'Missing required information.',
    'permission_denied': 'Permission Denied.',
    'unauthorized': 'Unauthorized user session.',
    'wrong_password': 'Authentication failed. Wrong password.',
    'user_mismatch': 'Blocking query from a mismatching account.',
    'register_try_again': 'Sign-up failed, please try again.',
    'wrong_address': 'We can not find the corresponding address.',
    'missing_username_password': 'Please provide email and password.',
    'unknown_user_agent': 'Unrecognized user agent. Please login agian.',
    'decryption_failure': 'Decryption Failure. Please refresh the page and try again.',
    'expired_token': 'Hash token expired, please request a new one.',
    'record_create': 'Error while creating data record.',
    'record_update': 'Error while updating data record.'
  },
  'breadcrumb': {
    'benefits': 'Avantages',
    'blog': 'Blog',
    'features': 'Traits',
    'contact': 'Nous contacter',
    'about': 'À propos de nous',
    'support': 'Soutenez nous',
    'cf': 'Info. de contact',
    'tou': 'Conditions d\'utilisation',
    'pp': 'Politique de confidentialité',
    'ju': 'Rejoignez-nous',
    'fb': 'Retour d\'information',
    'survey': 'Répondez à l\'enquête',
    'au': 'À propos de nous',
    'auth': 'Autorisation',
    'login': 'Connexion 🌐',
    'signup': 'S\'inscrire 🆔',
    'dashboard': 'Tableau de bord 🎨',
    'profile': 'Profil 🐣',
    'list_property': 'Propriétés',
    'property': 'Propriété',
    'accounting': 'Comptabilité',
    'precaution': 'précaution',
    'expense': 'Frais',
    'finance': 'Financer',
    'list_tenant': 'Locations',
    'tenant': 'Locataire',
    'show_tenant': 'Reçu',
    'agreement': 'Contrat de location',
    'application': 'Application de location',
    'settings': 'Paramètres',
    'password': 'Mot de passe 🔐',
    'change_password': 'Changer le mot de passe 🔐',
    'reset_password': 'Réinitialiser le mot de passe 🔐',
    'forgot_password': 'Mot de passe oublié 🔐',
    'inbox': 'Boîte de réception 📥',
    'welcome_letter': 'Lettre de bienvenue',
    'list_dashboard': 'Tableau de bord',
    'list_utilities': 'Utilitaires',
    'list_maintenance': 'Maintenance',
    'list_financials': 'Finances',
    'list_notifications': 'Notifications',
    'list_insurance': 'Assurance',
    'list_tax': 'Impôt',
    'rentals_dashboard': 'Tableau de bord des locations'

  },
  'benifits': {
    "title": 'Stress-free Home Maintenance and Data Intelligence',
    'saving': {
      'value': 'SAVING',
      'desc1': 'Save <b class="bold">2+ hours</b> every month that would be spent processing utility bills and managing rentals.',
      'desc2': 'Plus, save money by accessing Energy & Property <b class="bold">Tax Credit</b> claims.'
    },
    'smart': {
      'value': 'SMART',
      'desc1': 'Unlock your property’s <b class="bold">financial performance</b> and get access to an easy-to-read visual display.',
      'desc2': '<b class="bold">Automatically synchronize</b> your utility cost data two times each month without mannual effort.'
    },
    'simple': {
      'value': 'SIMPLE',
      'desc1': '<b class="bold">Digitalization</b> for rental lease agreement, rent collection reminders, rent receipts.',
      'desc2': 'Instantly store and retrieve property data with one simple click. <b class="bold">On-demand</b> abnormality detection.'
    },
    "secure": {
      'value': 'SECURE',
      'desc1': 'Protect your properties with <b class="bold">preventative guidelines</b> and handling protocols. ',
      'desc2': 'One-click <b class="bold">emergency contact list</b> to get help immediately in case of an emergency.'
    },
  },
  'auth': {
    'toggle-signup': 'Sign Up',
    'toggle-login': 'Login',
    'text-signup': 'Create a new account',
    'text-login': 'Please login to your account',
    'newsletter': 'Subscribe to Email Newsletter',
    'read_tou': 'You agree to the ',
    'sign_up': 'Start Using',
    'email-address': 'Email address',
    'password': 'Password',
    'confirm-password': 'Confirm your password',
    'message': 'Why not use a tool?',
    'tagline': 'Automated Maintenance Toolkit',
    'benefit': {
      '1': 'Saving',
      '2': 'Smart',
      '3': 'Simple',
      '4': 'Secure'
    }
  },
  'password': {
    'name': 'Username',
    'current': 'Current Password',
    'new': 'New Password',
    'format': 'At least 8 characters',
    'confirm_password': 'Confirm New Password',
    'confirm_description': 'Enter the new password again.',
    'password_updated': 'Your password has been updated.',
    'register_message': 'Please register as a new user.',
    'email': 'E-mail Address',
  },
  'home': {
    'newsletter-email': "Your email address",
    'lastest-news': 'Subscribe to receive the latest news.',
    'subscribe': 'Subscribe',
    'subscribed': 'Subscribed',
    'tagline': 'Residential House and Rental Property',
    'mp': 'Why not use a tool?',
    'mp2': 'Homeownership Simplified',
    'mp3': 'Unlock household data and liberate the way you manage your own space',
    'joinus': 'Join for Free',
    'signup_free': 'Sign Up For Free',
    'p1': 'Connect with your utility providers.',
    'p2': 'Check all of your bills in one place!',
    'tryout': 'Free Trial',
    'demop': 'Connect with your utility providers, Check all of your bills in one chart!',
    'benefits': {
      'title': 'Own properties in a simple way with our platform',
      'subtitle': 'From home ownership and leasing to monitoring and reporting, we have got you covered with our all-in-one solution.',
    },
    'saving': 'Saving',
    'savingp1': 'Save your time on managing the utility bills and payments.',
    'savingp2': 'Save your money by preventing unusual usage in the utilities.',
    'secure': 'Secure',
    'securep1': 'Protect your properties by supporting precautious procedures.',
    'securep2': 'One click to emergency contacts in case of urgent issues.',
    'simple': 'Simple',
    'simplep1': 'Automate your leasing, accounting and tax refunds.',
    'simplep2': 'Eliminate human errors and redundancy paperworks.',
    'smart': 'Smart',
    'smartp1': 'Integrate all relevant data and store them on one platform.',
    'smartp2': 'Apply business intelligence to monitor your properties.',
    'bottom': {
      'message_1': 'Start Managing Your Space Smartly…',
      'message_2': ' Our data can help you better manage your home or rental properties. <br>No payment information required.',
      'free_trial': 'Sign Up For Free'
    },
    'utility-monitoring': {
      'title': 'Utility Monitoring',
      'subtitle': 'AntsLabor tracks utility bills and helps save you money with refundable energy tax credits',
      'sub_feature_1': {
        'title': 'Easy to Track',
        'content': 'Organise and visual utility expenses, including: water, electricity, gas, and wifi, in one simple color-coded chart'
      },
      'sub_feature_2': {
        'title': 'Instant Alerts',
        'content': 'Be immediately alerted of water leakages, power outages and other utility emergencies'
      },
      'sub_feature_3': {
        'title': 'Save on Energy Costs',
        'content': 'Get up to $1247 in tax credits by claiming your utility costs'
      }
    },
    'emergency-precaution': {
      'title': 'Emergency Precaution',
      'subtitle': 'AntsLabor helps manage safety guidelines and security protocols to keep your property secure',
      'sub_feature_1': {
        'title': 'Preventative Measures',
        'content': 'Guidelines from <b class="bold">Fire Code</b> and tips for <b class="bold">smoke alarm maintenance</b> that will help keep your home-safety in check.'
      },
      'sub_feature_2': {
        'title': 'One Click Emergency Contact',
        'content': 'Get instant access to maintenance and emergency contact information'
      },
      'sub_feature_3': {
        'title': 'Online User Manual',
        'content': 'Digital online manual with built-in translation for newcomers'
      },
    },
    'rental-management': {
      'title': 'Rental Management',
      'subtitle': 'AntsLabor boosts administrating efficiency by 10X. Make managing rental properties simple and smart',
      'sub_feature_1': {
        'title': 'Intuitive Control',
        'content': 'Track and manage tenant payments with digital rent receipt emails and reminders'
      },
      'sub_feature_2': {
        'title': 'Instant Data Storage',
        'content': 'Store specially tailored rental and property data instantly using our secure database'
      },
      'sub_feature_3': {
        'title': 'Data Visualization',
        'content': 'Analyze property data and rental statistics with one-click, user-friendly displays'
      },
      'sub_feature_4': {
        'title': 'Online Lease Signing',
        'content': 'Use Ontario Standard Lease template to create your tenancy agreements quickly.'
      }
    },
    'property-data-intelligence': {
      'title': 'Property Data Intelligence',
      'subtitle': 'AntsLabor saves you 2+ hours a month managing utility bills and offers users Smart Insights into property financial performance',
      'sub_feature_1': {
        'title': 'Seamless Bookkeeping',
        'content': 'Save 2+hours monthly with hassle-free, automated expense bookkeeping'
      },
      'sub_feature_2': {
        'title': 'Smart Insights',
        'content': 'Easily collect and analyze rental income, utility & maintenance costs, property taxes and more'
      },
      'sub_feature_3': {
        'title': 'Tax Refunds',
        'content': 'Keep track of available tax refunds and easily generate tax forms to help optimize property income'
      },
      'bottom': {
        'message_1': 'Start managing your space smartly.',
        'message_2': 'No payment information required.',
      }
    },
  },
  'about-us': {
    'title': 'About Us',
    'brand': {
      'title': 'Our Brand',
      'tag': 'Apply our data to work for us.',
      'description': 'Amplify our Intelligence. Guide us in Darkness. Improve our Decision Making.',
      'part1': 'Atlas',
      'part1-pronounciation': '/\'ɑt.lɑs/',
      'part1-meaning': 'Guide, Map, and Pillar',
      'part2': 'IA',
      'part2-pronounciation': '',
      'part2-meaning': 'Intelligence Amplification',
    },
    'story': {
      'title': 'Our Story',
      'text': 'Bigger Innovation comes from real life experiences. One day, Jake was looking through his utility bills and found a water charge that was 10x the amount he typically paid in the last three months. As it turns out, it was caused by a leak in the washroom, which he may have never realized if he weren\'t looking through those bills closely! Since utility providers are never held responsible to inform us about abnormal fees or charges, homeowners are always on the hook to cover unexpected costs like this one. <br><br> As a homeowner, Jake had to spend a fair amount of time managing his place, which included reviewing utility bills, going through numerous tax files, monitoring for safety concerns, completing routine maintenance, keeping up with rentals and so much more. These problems kept him up at night, and as his thoughts raced he wondered: Why can\'t we manage our utility bills and tax files in a better way? How can we better protect our rights as renters or a rentees? Why not automate every single process to make the work easy and more accurate? <br><br> He finally had his eureka moment: AntsLabor! Every day since then, he has worked hard to put together a passionate team, and before he knew it - his dream became a reality!',
      'summary': 'The project is named AtlasIA. which is taken from the greek word Ataraxia, meaning a state of serene calmness.'
    },
    'business': {
      'title': 'Our Business',
      'text': 'AntsLabor is a B2C SaaS early stage PropTech startup. We are automating residential property management through distribution of data, computing power, and artificial intelligence. We are also exploring sustainable construction materials and world-class design for the next generation of smart house and CleanTech via IoT. <br><br> We aim to solve almost all of your housing problems by providing a platform that better monitors and manages your properties, creates operational efficiencies, and optimizes your finances.',
      'summary': 'Let the machines do the work so you can get back to enjoying your life!'
    },
    'team': {
      'title': 'Our Team',
      'text': 'Meet the passionate and skilled group of millennials that came together to make magic behind the scenes and provide home and property owners with the tools they need to feel empowered!'
    }
  },
  'payment': {
    'paypal': 'PayPal',
    'stripe': 'Stripe',
  },
  'support-us': {
    'title': 'Support Us',
    'question': {
      'header-p1': 'LIKE OUR PRODUCT',
      'header-p2': 'LET US KNOW',
      'welcome': "Glad to see you here! We're doing our best to bring you cutting-edge features that make house maintenance a breeze. Would you help us out by answering these questions?",
      'save-p1': 'How many',
      'save-p2': 'monthly can you save with our services?',
      'pricing': 'How many cups of ☕ are you willing to exchange for our service per month?',
    },
    'features': {
      'tenant': 'Tenancy Management',
      'reminder': 'Maintenance Reminder',
      'utility': 'Utility Usage Oversight',
      'finance': 'Organize Finance Data',
    },
    'coffee': {
      'five': '5 cups',
      'multiple': '4 or 3 or 2 cups ',
      'one': '1 cup'
    },
    'modal': {
      'header': 'More Coming Soon!',
      'body': 'Thank you for your continued interest in AntsLabor. We are dedicated to building features that are valuable to our landlords and homeowners community. Watch this space for our feature ready notification...',
      'footer': 'Leave your email here to get updated'
    },
    'donate': {
      'title': 'We couldn’t do this without you!',
      'desc': 'As a group of young innovators, we’ll face many obstacles as we work hard to bring our vision to life. With your continued support, we can work towards our goal of empowering more property owners by giving them the tools they need to better manage their tasks and simplify their lives. <br>For the price of a cup of coffee, you can help us continue to grow and evolve by making a donation online!',
      'donate': 'Donate',
      'paypal': 'Paypal',
      'donate_stripe': 'Donate via Stripe',
      'donate_paypal': 'Donate via PayPal',
    }
  },
  'dashboard': {
    'modal': {
      'body_create_property': 'Please fill in the address of your new property.'
    },
    'expandable': {
      'menu': 'Menu ▼',
      'change_password': 'Change Password ▼',
    },
    'map': 'Map',
    'yesterday': 'Yesterday',
    'today': 'Today',
    'tomorrow': 'Tomorrow',
    'todo': 'TODO',
    'started': 'STARTED',
    'done': 'DONE',
    'priority': 'Priority'
  },
  'profile': {
    'modal': {
      'body_prompt': 'This is an empty profile. <br>You can click<button type="button" class="btn btn-success btn-sm">&#10010;</button> to create a new property record.',
      'body_create_property': 'A new property has been registered. <br>You can click <button type="button" class="btn btn-outline-primary btn-sm">&#9997;</button> to fill in the property details.'
    },
    'expandable': {
      'contact': 'Contact Information ▲',
      'memo': 'Memorandum ▼'
    },
    'edit': 'Edit Profile ✍',
    'email': 'Account Email',
    'name': 'Display Name',
    'phone': 'Phone Number',
    'language': 'Language',
    'address': 'Mailing Address',
    'city': 'City / Town',
    'province': 'Province / State',
    'postal_code': 'Postal Code',
    'nationality': 'Nationality',
    'passport': 'Passport',
    'health_card': 'Health Card',
    'driver_licence': 'Driver Licence',
    'car_plate': 'Car Plate',
    'aeroplan': 'Aeroplan',
    'library_card': 'Library Card'
  },
  'property': {
    'modal': {
      'body_prompt': 'This is an empty property. <br>You can click<button type="button" class="btn btn-success btn-sm">&#10010;</button> to create a new tenant record.',
      'body_create_tenant': 'A new tenant has been registered. <br>You can click <button type="button" class="btn btn-outline-primary btn-sm">&#9997;</button> to fill in the tenant details.'
    },
    'breadcrumb': {
      'accounting': 'Accounting',
      'precaution': 'Precaution',
      'expense': 'Expense',
      'finance': 'Finance',
    },
    'expandable': {
      'tenants': 'List of Tenants ▼',
      'address': 'Address Detail ▼',
      'mortgage': 'Mortgage Information ▼',
      'precaution': 'Fire Precaution ▼',
      'emergency': 'Emergency Contact ▼',
      'data-sync-config': 'Account Setup ⚙️',
      'utility_details': 'Utility Cost Table ▼',
      'download_utility': 'Download CSV ⬇️',
      'monthly_expense': 'Monthly Expense ▲',
      'yearly_expense': 'Yearly Expense ▼',
      'utility_chart': 'Monthly Utility Chart ▲',
      'condition': 'Property Condition ▼',
      'oeptc': 'Ontario Energy and Property Tax Credit ▲',
      't776': 'Statement of Real Estate Rentals ▲',
      'balance_sheet': 'Balance Sheet ▼',
      'electricity_account': '⚡ Electricity',
      'water_account': '💧 Water',
      'internet_account': '📡 Internet',
      'boiler_account': '🔥 Heat / Boiler',
      'gas_account': '💨 Natural Gas',
      'public_service_account': 'Public Service (Property Tax) ▼',
      'insurance_account': 'House Insurance ▼'
    },
    'new': '✚ Add Property',
    'address': 'Address:',
    'street_number': 'Street #',
    'street_name': 'Street Name',
    'city': 'City:',
    'postal_code': 'Postal Code:',
    'suite': 'Suite Number',
    'move_in_date': 'Move-In Date',
    'owner': 'Owner:',
    'occupancy': 'Occupancy:',
    'vacancy': 'Vacancy:',
    'monthly_expense': 'Monthly Expense',
    'annual_expense': 'Annual Expense',
    'income': 'Rental Income',
    'mortgage_expandable': {
      'appraisal': 'Appraisal',
      'appreciation': 'Appreciation',
      'price': 'Purchase Price',
      'down_payment': 'Down Payment',
      'mortgage_principal': 'Mortgage Principal',
      'amortization': 'Amortization Period (Years)',
      'remaining_amortization': 'Remaining Amortization (Months)',
      'interest_rate': 'Interest Rate (%)',
      'remaining_principal': 'Remaining Principal',
      'mortgage_payment': 'Mortgage Payment',
      'mortgage_interest': 'Mortgage Interest',
      'principal_repayment': 'Principal Repayment',

      'rent': 'Monthly Rent Collection',
      'monthly_expense': 'Monthly Expense',
      'yearly_expense': 'Yearly Expense',
      'rental_gain_or_lose': 'Monthly Rental Balance',
      'mortgage_gain_or_lose': 'Monthly Mortgage Balance',
      'gain_or_lose': 'Gain or Lose',
      'appreciation_per_month': 'Appreciation per Month',
      'roi': 'Return on Investment (%/Year)',
    },
    'expense_expandable': {
      'utility_cost': 'Cost',
      'utility_provider': 'Provider',
      'utility_contacts': 'Contact',
      'utility_username': 'Account',
      'utility_password': 'Password',
      'sync': 'Synchronize expense data from utility provider.',
      'updated_on': 'Updated on: ',

      'electricity': 'Electricity',
      'water': 'Water',
      'internet': 'Internet',
      'boiler': 'Heat / Boiler',
      'gas': 'Natural Gas',

      'maintenance': 'Maintenance',
      'gardening': 'Gardening',
      'lawning': 'Lawning',
      'snow': 'Snow Removal',

      'renovation': 'Renovation & Addition',
      'depreciation': 'Furniture & Appliances Depreciation',
      'terminal_loss': 'Terminal Loss',
    },
    'condition_expandable': {
      'size': 'Size (sqft)',
      'year': 'Year Built',
      'roof': 'Roof Shape',
      'exterior': 'Exterior Material',
      'driveway': 'Driveway Slot',
      'bedroom': 'Bedroom Count',
      'bathroom': 'Bathroom Count',
      'kitchen': 'Kitchen',
      'stairs': 'Stairs',
    },
    'add_property': 'Add Property',
    'property_detail': 'Property Details',
  },
  'tenant': {
    'expandable': {
      'contact': 'Contact Information ▼',
      'payment': 'Rental Payment ▲',
      'habit': 'Hygiene Babits ▼'
    },
    'rent_receipt': 'Rent Receipt',
    'list_of_receipts': 'Receipt History Overview',
    'receipt_qrcode': 'Scan the QR code below to check this receipt online.',
    'agreement_qrcode': 'Scan the QR code below to check this agreement online.',
    'check_out_confirmation': 'Check Out Confirmation',
    'room_number': 'Room',
    'address': 'Address',
    'name': 'Name',
    'record': 'Receipt Record',
    'student_id': 'Student ID',
    'passport': 'Passport',
    'driver_licence': 'Driver Licence',
    'phone': 'Phone Number',
    'email': 'Email',
    'facebook': 'Facebook',
    'wechat': 'WeChat',
    'emergency_contact': 'Emergency Contact',
    'rental_start_date': 'Rental Start Date',
    'rental_end_date': 'Rental End Date',
    'rent': 'Monthly Rent',
    'currency': 'Currency',
    'cycle': 'Lease Term',
    'payment_method': 'Payment Method',
    'deposit': 'Deposit',
    'balance': 'Balance',
    'paid': 'Total Payment Made',
    'owing': 'Owing',
    'language': 'Language',
    'late_payment_tally': 'Late Payment Days',
    'pets': 'Pets',
    'drinking': 'Drinking',
    'smoking': 'Smoking',
    'marijuana': 'Marijuana',
    'delete_confirm': 'You are going to Delete this property permanently. Do you confirm to do it?',
    'archive_or_delete': 'You have the lease and rent data saved in this property. If you delete it, you will lose your data permanently. You can choose Archive to keep your data safe in your personal folder. Please confirm which action you want to take.',
    'tenant_email': 'Tenant Email',
    'tenant_lang': 'Tenant Language',
    'tenant_addr': 'Tenant Address',
    'tenant_start': 'Tenant Start Date',
    'tenant_end': 'Tenant End Date',
    'tenant_phone': 'Tenant Phone Number',
    'tenant_rent': 'Monthly Rent',
    'property': 'Property',
    'status': 'Status',
    'due_date': 'Due Date',
    'of_month': 'of each month',
    'payment': 'Payment',
    'document': 'Document',
    'receipt_issued': 'Receipt Issued',
    'full_amount': 'Full Amount',
    'other_amount': 'Other Amount',
    'delay_tooltip': 'The rent payment has been delayed. Click to confirm the payment',
    'remind': 'Remind',
    'remind_tooltip': 'Only one reminder per day is allowed.',
    'reminder_sent': 'Reminder Sent',
    'payment_modal': {
      'header': 'Payment Confirmation',
      'enter_amount': 'Please confirm the total amount you have received',
      'confirm': 'Received ?',
      'postponed': 'Postponed',
      'cancel': 'Cancel',
      'do_you_confirm': {
        '1': 'Do you confirm that the rent of $',
        '2': 'has been paid by',
        '3': 'for',
        '4': 'and ready to send out the receipt?',
      }
    },
    'balance_paid': 'Paid',
    'other_amount_confirm': 'Please enter the Amount you have received from Tenant',
    'delay': 'Delay',
    'reminder_mail_sent': 'You have sent a payment reminder email to',
    'add_tenant': '✚ Add Tenant',
    'new_vacancy': '✚ New Vacancy',
    "tenant_detail": 'Tenant Details',
    "receipt_history": 'Receipt History',
    "receipt": {
      "receipt": 'Receipt',
      "date": 'Date',
      "amount": 'Amount',
      "deposit": 'Deposit',
      "cycle": 'Lease Term',
    },
    "overdue": 'Overdue',
    "rating": "Rating"
  },
  'utility': {
    'headline': 'Want to see data like this? Synchronize your accounts below to check your own data!',
    'monthly_expense': 'Monthly Expense',
    'number_of_tenants': 'Number of Tenants',
    'monthly_expense_each_tenant': 'Expense on Each Tenant',
    'deposit': 'Refundable Deposit',
    'reminder': 'Reminder',
    'due_date': 'Due Date',
    'countdown1': 'Countdown',
    'days': 'Days',
    'countdown2': 'until next payment',
  },
  'application': {
    'expandable': {
      'credit_score': 'Credit Score Test ▼',
      'details': 'Agreement Details ▼'
    },
    'title': 'RENTAL REQUEST FORM'
  },
  'lease': {
    'ok': 'OK',
    'or': 'OR',
    'title': 'RESIDENTIAL TENANCY AGREEMENT',
    'note': '⚠️ Note: ',
    'header-note': 'This tenancy agreement (or lease) is required for tenancies entered into on April 30, 2018 or later. It does not apply to care homes, sites in mobile home parks and land lease communities, most social housing, certain other special tenancies or co-operative housing (see Part A of General Information). Residential tenancies in Ontario are governed by the Residential Tenancies Act, 2006. This agreement cannot take away a right or responsibility under the Residential Tenancies Act, 2006. Under the Ontario Human Rights Code, everyone has the right to equal treatment in housing without discrimination or harassment. All sections of this agreement are mandatory and cannot be changed.',
    'add': 'ADD',
    'save': 'SAVE',
    'cancel': 'CANCEL',
    'preview': 'PREVIEW',
    'bar': {
      'parties': 'The Parties',
      'occupants': 'The Occupants',
      'premises-1':'The premises and',
      'premises-2': 'management',
      'documents-1': 'Serving of ',
      'documents-2': 'Documents',
      'term-1': 'Beginning and ',
      'term-2': 'term of agreement',
      'key': 'Key Deposit',
      'smoking': 'Smoking',
      'insurance-1': "Tenant's",
      'insurance-2': "Insurance",
      'rent': "Rent",
      'responsibility-1': "Responsibilty of ",
      'responsibility-2': "The Tenant",
      'deposit': "Deposit",
      'inspection': "Inspection Report",
      'miscelaneous': "Miscelaneous",
      'signatures': "Signatures",
    },
    'form': {
      'name': 'Name',
      'address': 'Address',
      'city': 'City',
      'province': 'Province',
      'postal-code': 'Postal Code',
      'email': 'Email',
      'cell': 'Cell',
      'street': 'Street',
    },
    'parties': {
      'title': 'PARTIES TO THE AGREEMENT',
      'header-one': 'this agreement is made duplicate between',
      'landlord': 'LANDLORD',
      'tenant': 'TENANT',
    },
    'occupants': {
      'title': 'THE OCCUPANTS',
      'header-one': 'Name all adults and/or children who will occupy the premises:',
      'property-type': 'Type of property',
      'note': 'Only tenants and occupants named may live in the premises without written consent of the landlord',
      
    },
    'premises': {
      'title': 'THE PREMISES AND MANAGEMENT',
      'header-one': 'The landlord will rent to the tenant and the tenant will rent from the landlord the following:',
      'header-two': 'Do you have an agent or property manager for this address?',
      'header-three': 'Do you have a superintendant for this address?',
      'property-type': 'Type of property',
      'note': 'Only tenants and occupants named may live in the premises without written consent of the landlord',
      'tenant-address': "Tenant's mailing address",
      'tenant-emergency': "Tenant Emergency Contact",
      'tenant-emergency-phone': "Tenant Emergency Phone Number(s)",
      
    },
    'docs': {
      'title': 'THE SERVING OF DOCUMENTS',
      'header-one': 'All Notices to Terminate or service of documents to the landlord shall be in writing and served in person, by registered mail, or by any other means authorized by the Landlord to:',
      'check-one': 'The landlord / owner',
      'check-two': 'The agent or property manager',
      'check-three': 'The superintendant at the above noted address',
    },
    'term': {
      'title': 'BEGINING AND TERM OF AGREEMENT',
      'header-one': 'Select Lease Term',
      'fixed': 'FIXED TERM',
      'fixed-desc': 'The lease starts on a fixed date and will end on a fixed date.',
      'monthly': 'MONTH-TO-MONTH',
      'monthly-desc': "The lease starts on a fixed date but won't end on a fixed date. It continues each month until the landlord or the tenant gives further notice.",
      'lease-begins': 'Lease Begins On',
      'lease-ends': 'Lease Ends On',
      'auto': ' Automatically goes on Month-to-Month on the end of the lease.',
    },
    'key': {
      'title': 'KEY DEPOSIT',
      'not-required': 'A key deposit is not required',
      'desc-one': 'The tenant will pay a refundable key deposit of',
      'desc-two': 'to recover the cost of replacing the keys, remote entrydevices or cards if they are not returned to the landlord at the end of the tenancy.',
      'desc-three': 'If a refundable key deposit is required, provide description and number of keys, access cards and remote entry devices.',
      'note': 'The key deposit cannot be more than the expected replacement cost. See Part H in General Information.',
    },
    'smoking': {
      'title': 'SMOKING',
      'header-one': 'Under provincial law, smoking is not allowed in any indoor common areas of the building. The tenant agrees to these additional rules on smoking:',
      'rules-desc': 'Provide description of smoking rules',
      'none': 'None',
      'rules': 'Smoking rules',
      'note': 'In making and enforcing smoking rules, the landlord must follow the Ontario Human Rights Code. See Parts M and S in General Information.',
    },
    'insurance': {
      'title': "TENANT'S INSURANCE",
      'header-one': "Tenant's Insurance",
      'none': 'None',
      'required': 'The tenant must have liability insurance at all times. If the landlord asks for proof of coverage, the tenant must provide it. It is up to the tenant to get contents insurance if they want it.',
    },
    'rent': {
      'title': "RENT",
      'header-one': "Tenant's Insurance",
      'info-a-one': 'a) Rent is to be paid on the ',
      'info-a-two': 'of each',
      'info-b-one': 'b) The tenant will pay the following rent:',
      'info-b-two': 'Base rent for the rental unit',
      'parking': 'Parking (if applicable)',
      'add': ' +  Add Service or Utility',
      'total': 'Total Rent (Lawful Rent)',
      'note-one': "2229 (2018/01) Page 3 of 13. This is the lawful rent for the unit, subject to any rent increases allowed under the Residential Tenancies Act, 2006. For example, the landlord and tenant may agree to a seasonal rent increase for additional services of air conditioning or a block heater plug-in. This amount does not include any rent discounts (see Section 7 and Part G in General Information).",
      'note-two': "The tenant cannot be required to pay rent by post-dated cheques or automatic payments, but can choose to do so.",
      'note-three': "The landlord's administration charge for an NSF cheque cannot be more than $20.00.",
      'info-c': 'c) Rent is payable to:',
      'info-d': 'd) Rent will be paid using the following methods:',
      'info-e-one': 'e) If the first rental period is a partial period, the tenant will pay a partial rent of',
      'info-e-two': 'on',
      'info-e-three': 'this partial rent covers the rental of the unit from',
      'info-e-four': 'to',
      'info-f-one': "f) If the tenant's cheque is returned because of non-sufficient-funds (NSF), the tenant will have to pay the landlord's administration charge of",
      'info-f-two': "plus any NSF charges made by the landlord's bank.",
      'cash': 'Cash',
      'cheque': 'Cheque',
      'other': 'Other',
    },
    'responsibility': {
      'title': "RESPONSIBILITY OF THE TENANT",
      'header-one': "The tenant is responsible for the following (click all that apply):",
      'header-two': 'Provide details about services or list any services if needed (if necessary add additional pages)',
      'header-three': 'The following utilities are the responsibility of:',
      'landlord': 'Landlord',
      'tenant': 'Tenant',
      'electricity': 'Electricity:',
      'heat': 'Heat:',
      'water': 'Water:',
      'details': "If the tenant is responsible for the utilies, provide details of the arrangement, e.g. tenant sets up account with and pays the utility provider, tenant pays a portion of the utility costs",


    },
    'deposit': {
      'title': "DEPOSIT",
      'header-one': "deposit of",
      'header-one-b': "(not to exceed one month's rent)",
      'rent-monthly': ' If the rent is collected monthly, one month’s rent can be collected as deposit and if the rent is paid weekly, one week’s rent can be collected at the beginning of the tenancy. The landlord can then pay the tenant 6 percent compound interest at the beginning of the next year and preceding years. This deposit can be put towards the last month’s rent.',
      'note-one': "Ontario sets provincial rent guidelines annually, published in August. Rent increase by landlords can only occur once every 12 months and 90 days written notice is required. These increases must abide by the annual provincial guidelines. If the landlord wishes to increase the rent beyond the guidelines, an application to the Ontario Rent Tribunal must be filed to obtain permission. ",
      'note-two': "This is not a security deposit or damage deposit as security and damage deposits are not permitted in Ontario.",
    },
    'inspect': {
      'title': "INSPECTION REPORT",
      'header-one': "An inspection of the premises and the preparation of a written inspection report signed by the landlord and tenant and prepared within 1 day of the start of the tenancy and within 1 day of the end of the tenancy is . If an inspection report is prepared, it shall form part of the agreement.",
    },
    'misc': {
      'title': "MISCELANEOUS",
      'header-one': 'The landlord and tenant promise to comply with the conditions set out in Schedule “A.” The tenant acknowledges receipt of the rules of the residential premises and residential property which are attached hereto as Schedule “___.”',
      'header-two': 'If the rent is unpaid one day after the due date, the landlord has the right to file an application to the Ontario Rent Tribunal for rent arrears. If the tenant does not pay the rent within two weeks, eviction can occur.',
      'header-three': 'All Notices to Terminate must be in writing. The tenant must give 60 days notice in writing to the landlord before the rent is due. If it is the landlord terminating the tenancy, depending on the situation, he or she must allow tenants the following notice periods:',
      'header-three-a': '14 days for non-payment of rent, 7 days notice for weekly or daily tenancy',
      'header-three-b': "2 months for landlord's use of property",
      'header-three-c': "20 days for cause or conduct",
      'header-four': "This agreement is for the benefit of and is binding on the landlord and tenant and their heirs, executors, administrators, and assigns.",
      'header-five': "Any or all tenants signing this lease take full responsibility for all terms and conditions.",
      'note-one': "If the tenant breaches conditions of the Ontario Rental Housing Tribunal, the landlord can apply to the Tribunal without giving the tenant notice.",
      'note-two': "A tenant may not end a fixed term lease until the end of the term. The landlord can only terminate the fixed term lease based on legitimate reasons in the legislation and cannot terminate solely based on the expiration of the fixed term agreement. If the tenant does not give notice to terminate at the end of the fixed term agreement, the lease automatically goes to month-to-month and the landlord cannot force the tenant to sign another fixed term lease. All conditions from previous lease will remain the same unless re-negotiated between the landlord and tenant. ",
    },
    'signatures': {
      'title': "SIGNATURES",
      'header-one': 'Schedule "A"',
      'header-two': "The following conditions apply where the relationship of landlord and tenant exists, regardless of any agreement, declaration, lease, waiver, or other statement to the contrary:",
      'conditions': "CONDITIONS:",
      'header-three': '1. Sublet',
      'condition-a-one': 'The tenant may assign or sublet the residential premises to another person with the consent of the landlord.',
      'condition-a-two': 'A new tenant must assume all of the rights and duties under the existing tenancy agreement, at the same rent. ',
      'condition-a-three': 'The landlord must not charge a fee or receive a benefit, directly or indirectly, for giving this consent.',
      'condition-a-four': 'If a landlord unreasonably withholds consent to assign or sublet or charges a fee, the tenant may apply to the Ontario Rent Tribunal for arbitration.',
      'repairs': '1. Repairs',
      'landlords': '(a) Landlord’s Duties:',
      'condition-b-one': 'The landlord must provide and maintain the residential premises and residential property in a reasonable state of decoration and repair, making the residential ',
      'condition-b-two': 'premises and the residential property suitable for occupation by a reasonable tenant. The landlord must comply with health, safety, and housing standards required by law.',
      'condition-b-three': 'If the landlord is required to make a repair to comply with the above duties, the tenant may discuss it with the landlord. If the landlord refuses to make the repair,',
      'condition-b-four': 'the tenant may apply to the Ontario Rent Tribunal for the completion and costs of the repair.',
      'tenant': '(b) Tenant’s Duties:',
      'condition-c-one': 'The tenant must maintain ordinary health, cleanliness, and sanitary standards throughout the residential premises and residential property.',
      'condition-c-two': 'The tenant is not responsible for reasonable wear and tear to the residential premises.',
      'emergency': '(c) Emergency Repairs:',
      'condition-d-one': 'The landlord must post the name and telephone number of the designated contact person for emergency repairs.',
      'condition-d-two': 'The tenant must make at least two attempts to notify the person designated by the landlord,',
      'condition-d-three': 'and give a reasonable time for completion of the emergency repairs by the landlord.If the emergency repairs are still required,',
      'condition-d-four': 'the tenant may undertake the repairs and deduct the cost from the next month’s rent, provided a statement of account and receipts are given to the landlord.',
      'condition-d-five': 'The landlord may take over completion of the emergency repairs at any time',
      'condition-d-six': 'Emergency repairs must be urgent and necessary for the health and safety of persons or preservation of property and are limited to:',
      'condition-d-list-one':'major leaks in the pipes or roof,',
      'condition-d-list-two':'damaged or blocked water or sewer pipes or plumbing fixtures,',
      'condition-d-list-three': 'repairs to the primary heating system, and',
      'condition-d-list-four': 'defective locks that give access to the residential premises.',
      'locks': '3. Locks',
      'condition-e-one': 'Neither the tenant nor the landlord may change or add a lock or security device (for example, a door chain) to residential premises unless both agree,',
      'condition-e-two': 'or unless ordered by an arbitrator. In an emergency, the landlord may change the lock on the main door of the residential property and the tenant may',
      'condition-e-three': 'change a defective lock on the residential premises and promptly provide the other party with a copy of the new key.',
      'entry': '4. Entry of Residential Premises by the Landlord',
      'condition-f-one': 'The landlord may enter the residential premises only if one of the following applies:',
      'condition-f-two': 'the landlord gives the tenant a written notice which states why the landlord needs to enter the residential premises and specifies a reasonable time not sooner than 24 hours and not later than 72 hours from the time of giving notice; entry must only occur during daylight hours (8 a.m. to 8 p.m.)',
      'condition-f-three': 'there is an emergency;',
      'condition-f-four': 'the tenant gives the landlord permission to enter at the time of entry or not more than one month before the time of entry for a specific purpose',
      'condition-f-five': 'the tenant has abandoned the residential premises',
      'condition-f-six': 'the landlord has the order of an arbitrator or court saying the landlord may enter the residential premises;',
      'pets': '5. Pets and Smoking',
      'condition-g-one': 'If the tenancy agreement does not permit pets or smoking in the rental unit, the landlord has the right to give a Notice of Termination if the tenant has pets or smokes.',
      'condition-g-two': 'If the landlord permits pets on the premises but the pet(s) disrupt the peace and enjoyment of other tenants, the landlord has the right to file a Notice of Termination. ',
      'protection': '6. Application of the Tenant Protection Act',
      'condition-h-one': 'The terms of this tenancy agreement and any changes or additions to the terms may not contradict or change any right or duty under the Tenant Protection Act or a regulation made under the act and to the extent that a term of this tenancy agreement does contradict or change a right or duty under the Tenant Protection Act or a regulation made under that act the term of this tenancy agreement is void.',
      'condition-h-two': 'Any change or addition to this tenancy agreement must be agreed to in writing and initialed by both the landlord and tenant and must be reasonable. If a change is not agreed to in writing, is not initialed by the landlord and tenant, or is not reasonable it is not enforceable.',
      'arbitration': '7. Arbitration of Disputes',
      'condition-i-one': ' Despite any other provision of this tenancy agreement, under the Tenancy Protection Act a tenant has the right to apply for arbitration to resolve a dispute.',
      'additional': '8. Additional Terms'


    },
  },
  'balance_sheet': {
    'title': 'Balance Sheet',
    'assets': 'Assets',
    'liabilities': 'Liabilities',
    'equity': 'Equity',
    'current_assets': 'Current Assets',
    'fixed_assets': 'Fixed Assets',
    'total_assets': 'Total Assets',
    'current_liabilities': 'Current Liabilities',
    'long_term_liabilities': 'Long Term Liabilities',
    'total_liabilities': 'Total Liabilities',
    'total_equity': 'Total Equity'
  },
  'oeptc': {
    'title': 'OEPTC - Ontario Energy and Property Tax Credit',
    'partA': 'Part A - Amount paid for a principal residence',
    'line_61120_text': 'Property Tax paid for your principal residence',
    'line_61210_text': 'Home Energy costs paid for your principal residence',
    'partB': 'Part B – Declaration',
    'declaration': 'I declare the following information about my principal residences',
    'address': 'Address',
    'postal': 'Postal Code',
    'months': 'Number of Months',
    'amount': 'Amount paid',
    'is_long_term_care': 'Is a long-term care home?',
    'municipality': 'Name of Municipality'
  },
  't776': {
    'title': 'T776 - Statement of Real Estate Rentals',
    'part3': 'Part 3 - Income',
    'line_8141_text': 'Enter the total of your gross rents in the year you receive them',
    'line_8230_text': 'Other income',
    'line_8299_text': 'Enter this amount on your income tax and benefit return (T1) on line 160',
    'total_gross_income': 'Total Gross Income',
    'part4': 'Part 4 - Expenses',
    'line_8521_text': 'Advertising',
    'line_8690_text': 'Insurance',
    'line_8710_text': 'Interest and bank charges',
    'line_8810_text': 'Office expenses',
    'line_8860_text': 'Professional fees (includes legal and accounting fees)',
    'line_8871_text': 'Management and administration fees',
    'line_8960_text': 'Repairs and maintenance',
    'line_9060_text': 'Salaries, wages, and benefits (including your contributions)',
    'line_9180_text': 'Property taxes',
    'line_9200_text': 'Travel',
    'line_9220_text': 'Utilities',
    'line_9281_text': 'Motor vehicle expenses (not including capital cost allowance)',
    'line_9270_text': 'Other expenses',
    'total_expenses': 'Total Expenses',
    'line_9949_text': 'Total expenses for personal portion',
    '7': 'Amount 7',
    'amount_7_text': '[Total Expenses - Line 9949] Deductible Expenses',
    'line_9369_text': '[Line 8299 - Amount 7] Net income (loss) before adjustments',
    'subtotal': 'Subtotal',
    '9': 'Amount 9',
    'amount_9_text': 'Other expenses of the co-owner. Calculate personal share of net income from line 9369.',
    'line_9945_text': 'Other deductible expenses you have as a co-owner',
    '11': 'Amount 11',
    'amount_11_text': '[Amount 9 - Line 9945]',
    'line_9947_text': 'Recaptured capital cost allowance',
    '13': 'Amount 13',
    'amount_13_text': '[Amount 11 + Line 9947]',
    'line_9948_text': 'Terminal loss from depreciable property',
    '15': 'Amount 15',
    'amount_15_text': '[Amount 13 + Line 9948]',
    'line_9936_text': 'Total capital cost allowance',
    'line_9946_text': '[Amount 15 - Line 9936] Enter this amount on your income tax and benefit return (T1) on line 126',
    'net_income': 'Net Income (Loss)'
  },
  'quotes': {
    '1': 'Why not use a tool?'
    // '1': 'A turnkey solution for property management.',
    // '2': 'Make your house smarter and more carefree.',
    // '3': 'Upgrade your home like a self-repairing castle.',
    // '4': 'Create online presence of your properties via digital replicas.'
    // '5': 'Did you know Ants planted Trilliums?',
    // '6': 'Rooting for human civilization!',
    // '7': 'Empowering individuals, make everyone godlike!',
    // '8': 'Apply scientific approaches to unleash the underdeveloped human capital.',
    // '9': 'Diversity is our strength.'
  },
  'footer': {
    'canadian': 'Proudly 🇨🇦 Canadian',
    'copyright': 'All Rights Reserved',
    'twitter': 'Twitter',
    'linkedin': 'LinkedIn',
    'facebook': 'Facebook',
    'wechat': 'Wechat',
    'email': 'Email'
  },
  'contact': {
    'title': 'Contact Us',
    'address': {
      '1': '<span>&nbsp;&nbsp;&nbsp;51 Pettigrew Court, </span><span>Markham, ON L3S 1K4</span>',
      '2': '<span>&nbsp;&nbsp;&nbsp;6181 Disputed Road, </span><span>LaSalle, ON N9H 0C1</span>'
    },
    'phone': '+1 (416) 938-0314',
    'email': '<a href="mailto:hello@antslabor.com?Subject=AntsLabor" target="_top">hello@AntsLabor.com</a>',
    'wechat': ' AntsTechnology ',
    'book': ' Book an online meeting ',
    'meeting': '<a href="https://calendly.com/antslabor/jake-queue"> Click here to book an online meeting </a>'
  },
  'forgot_password': {
    'message': 'Email with instructions was sent to your email address. Please check it!',
    'email_subject': 'AntsLabor - Reset Password',
    'link_text': 'Please follow this link to reset your password.'
  },
  'covid19': {
    'stay-home': "⚠️ COVID-19 – You don't have to go out for mailbox."
  },
  'features': {
    'utility': 'Synchronize and visualize all the utility expenses in one chart.',
    'secure': 'Precautious safety procedures with newcomer-friendly translation.',
    'rental': 'Manage tenants with the digital rent reminder and digital rent receipt.',
    'accounting': 'Provide accounting documents to apply for available tax credits.',
    'mortgage': 'Publish your real estate information to advertising platforms.'
  },
  'benefits': {
    'saves': {
      'title': 'Saves',
      'text1': 'Save your time on managing the utility bills and payments.',
      'text2': 'Save your money by preventing unusual usage in the utilities. '
    },
    'secure': {
      'title': 'Secure',
      'text1': 'Protect properties by supporting precautious procedures.',
      'text2': 'One click to emergency contacts in case of urgent issues.'
    },
    'simple': {
      'title': 'Simple',
      'text1': 'Automate your leasing, accounting and tax refunds.',
      'text2': 'Eliminate human errors and redundancy paperworks.'
    },
    'smart': {
      'title': 'Smart',
      'text1': 'Integrate all relevant data and store them on one platform.',
      'text2': 'Apply business intelligence to monitor your properties. '
    }
  },
  'letter': {
    'welcome': 'Welcome to AtlasIA'
  },
  'fire_precaution': {
    'smoke_alarm': {
      'title': 'SMOKE ALARM MAINTENANCE CHECKLIST',
      'routine': 'ROUTINE TEST AND MAINTENANCE',
      'annual': 'ANNUAL TEST AND MAINTENANCE ONLY',
      'text1': 'Smoke alarm is properly installed. The alarm is securely fastened to the wall or ceiling.',
      'text2': 'Smoke alarm is clear without excessive grease and dirt accumulations. Ventilation holes on the smoke alarm are clean and free of obstructions.',
      'text3': 'Smoke alarm is fully functional. The alarm signal sounds when the test device is operated. ',
      'text4': 'Smoke alarm is powered by ',
      'expiration': 'which expires after ',
      'text5': 'For battery operated smoke alarms: ',
      'replaced': 'Battery has steady supply of power. Otherwise, please replace it.',
      'connected': 'Battery has been securely connected to the clips.',
      'corrosion': 'Battery terminals are free of corrosion and signs of leakage.',
      'ac_wiring': 'AC Wiring',
      'std_battery': 'Standard Battery',
      'll_battery': 'Long Life Battery'
    },
    'carbon_monoxide': {

    }
  },
  'matrix': {
    'user_count': 'Homeowners',
    'property_count': 'Properties',
    'tenant_count': 'Tenants',
    'agreement_count': 'Agreements',
    'pages_count': 'Paper Pages',
    'trees_count': ' Trees Rescued 🌳🌲'
  },
  'emergency_contact': {
    'title': 'Emergency Contact',
    'number': 'Contact Number',
    'police': 'Police',
    'fire': 'Fire',
    'esa': 'Electrical Safety Authority',
    'ambulance': 'Ambulance',
    'gas': 'Gas Company',
    'plumber': 'Plumber',
    'water': 'Water',
    'security': 'Security',
    'internet': 'Internet'
  }
}

