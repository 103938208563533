// eslint-disable-next-line
export var dictionary_cns = {
  'util': {
    'unEscapeTag': '{{- tag}}'
  },
  'language': {
    'en': '🇨🇦 EN',
    'fr': '🇫🇷 FR',
    'cns': '🇨🇳 中文'
  },
  'flag': '🇨🇳',
  'head': {
    'title': '工乙科技 物业软件',
    'slogan': '烦人的事情交给我们吧',
    'company': '由☕️ , 🎵 和💗 凝聚而成 工乙科技有限公司'
  },
  'logo': {
    'link': '/static/logo-cn.png',
    'link-mobile': '/static/logo-circle.png',
    'link-sidebar': '/static/logo-cn.png'
  },
  'general': {
    'welcome': '欢迎光临',
    'hello': '👋 你好',
    'date': '📆 日期',
    'language': '🌏 语言: ',
    'currency': '💱 货币: ',
    'success': '成功 🎉',
    'ok': '🆗 好的',
    'yes': '✔ 是的',
    'no': '✖ 没有',
    'edit': '编辑',//'编辑 📝',
    'remove': '❌',
    'delete': '删除',//'删除 ❌',
    'continue': '继续 ⏩',
    'return': '返回',
    'save': '保存 💾',
    'refund': '退款',
    'print': '打印 🖨',
    'send': '发送 🚢',
    'show': '展示 💻',
    'export': '输出 📦',
    'upload': '📤 上传',
    'choose_file': '🗂 选择照片',
    'free_trial': '免费试用',
    'signup': '注册账号',
    'login': '登录',
    'signin_signup': '注册/登录',
    'home': '访问主页 🏡',
    'change_password': '更换密码',
    'reset_password': '重置密码',
    'forgot_password': '忘记密码',
    'logout': '退出账号',
    'confirmation': '确认删除',
    'are_you_sure': '你确定吗？',
    'alert': '提示❗️',
    'enter': '进入',
    'submit': '确认',//'确认 👍',
    'abort': '撤销',//'撤销 ⛔',
    'confirm': '确定',//'确定 ✅',
    'cancel': '取消',//'取消 ❎',
    'username': '用户名 🐣',
    'password': '密码 🔒',
    'birthday': '生日 🎂',
    'search': '搜索',
    'copy': '复制',
    'join-us': '加入我们',
    'details': '合约细节',
    'manual': '使用指南',
    'pitch': '路演稿件',
    'share': '分享',
    'view_more': '查看更多信息'
  },
  'error': {
    'invalid_input': '不合规的输入。',
    'try_again': '请再试一次。',
    'is_taken': ' 已被占用。',
    'unexpected_amount': '超出预期的支付额度。',
    'api_not_found': '未能找到这个API。',
    'data_not_found': '未能找到指定的信息。',
    'user_not_found': '未能找到输入的用户名。我不认识这个用户。',
    'user_already_exist': '用户名已被占用。',
    'missing_required_element': '缺少必要的信息。',
    'permission_denied': '未经许可的访问。',
    'unauthorized': '未经授权的访问，需要用户认证。',
    'wrong_password': '认证失败。密码错误。',
    'user_mismatch': '已阻拦来自不匹配用户的信息访问。',
    'register_try_again': '注册失败, 请再试一次。',
    'wrong_address': '没有找到匹配的地址。',
    'missing_username_password': '请提供用户名和密码。',
    'unknown_user_agent': '未经认识的客户端。请再次登陆账号。',
    'decryption_failure': '解密失败，清刷新页面重试。',
    'expired_token': '验证码已过期，请再次申请。',
    'record_create': '数据记录创建失败。',
    'record_update': '数据记录更新失败。'
  },
  'breadcrumb': {
    'benefits': '产品特色',
    'blog': '博客',
    'features': '产品功能',
    'contact': '联系我们',
    'about': '关于我们',
    'support': '支持我们',
    'cf': '联系方式',
    'tou': '使用权条款',
    'pp': '隐私权条款',
    'ju': '加入团队',
    'fb': '提交反馈',
    'survey': '问卷调查',
    'au': '关于我们',
    'auth': '帐号验证',
    'login': '登陆 🌐',
    'signup': '注册 🆔',
    'dashboard': '仪表板 🎨',
    'profile': '个人资料 🐣',
    'list_property': '物业列表',
    'property': '名下物业',
    'accounting': '会计税务信息',
    'precaution': '预防措施信息',
    'expense': '用度花费信息',
    'finance': '金融贷款信息',
    'list_tenant': '住户列表',
    'tenant': '住户',
    'show_tenant': '住户信息',
    'agreement': '租约合同',
    'application': '入住申请',
    'settings': '帐号设置',
    'password': '更换密码 🔐',
    'change_password': '更换密码 🔐',
    'reset_password': '重置密码 🔐',
    'forgot_password': '忘记密码 🔐',
    'inbox': '收件箱 📥',
    'welcome_letter': '欢迎信',
    'list_dashboard': '总览表',
    'list_utilities': '水电用度',
    'list_maintenance': '维护保养',
    'list_financials': '金融财政',
    'list_insurance': '保险单据',
    'list_tax': '税务数额',
  },
  'benifits': {
    "title": '畅享0压力的高智能数据物业管理工具',
    'saving': {
      'value': '更节省',
      'desc1': '每月节省<b class="bold">2个小时以上</b>的时间来处理水电用度账单和物业租赁管理',
      'desc2': '申报地税和能源消耗相关的<b class="bold">退税返利</b>，还能节省能源消耗成本。'
    },
    'smart': {
      'value': '更智能',
      'desc1': '通过<b class="bold">可视化显示</b>租赁物业统计信息，智能洞察物业财务绩效。',
      'desc2': '<b class="bold">全自动</b>同步水电用度消费数据，每月两次无需用户手动参与。'
    },
    'simple': {
      'value': '更简单',
      'desc1': '通过自动化物业租赁管理，租金收据发行和税收减免申报，将每月<b class="bold">工作效率提高10倍</b>。',
      'desc2': '<b span class= "bold">一键</b>即时存储获取物业相关数据。异常情况自动发起提醒。'
    },
    "secure": {
      'value': '更安全',
      'desc1': '提供紧急<b span class= "bold" >预防指引</b>和安全处理手册来保护您的物业。',
      'desc2': '可以查阅<b class="bold">紧急联系簿</b>使您在紧急情况下立即获得帮助。'
    },
  },
  'auth': {
    'toggle-signup': '注册',
    'toggle-login': '登录',
    'text-signup': '创建一个新的用户帐号',
    'text-login': '请登录你的帐号',
    'newsletter': '订阅相关资讯',
    'read_tou': '已经了解并同意 ',
    'sign_up': '开始使用',
    'email-address': '电子邮箱',
    'password': '密码',
    'confirm-password': '重复密码',
    'message': '干嘛不借助工具呢？',
    'tagline': '智能房屋管理系统',
    'benefit': {
      '1': '更节省',
      '2': '更智能',
      '3': '更简洁',
      '4': '更安全'
    }
  },
  'password': {
    'name': '用户名',
    'current': '现用密码',
    'new': '新密码',
    'format': '至少8个字',
    'confirm_password': '确认新密码',
    'confirm_description': '请再次输入新密码以确认',
    'password_updated': '主人，你的密码已更新。',
    'register_message': '到此一游，留个名吧。',
    'email': '电子邮箱',
  },
  'home': {
    'newsletter-email': "您的邮件地址",
    'lastest-news': '订阅以关注最新消息.',
    'subscribe': '订阅',
    'subscribed': '已订阅',
    'tagline': '屋企同出租房管理软件',
    'mp': '为何不借助工具呢？',
    'mp2': '极致简洁的房产体验',
    'mp3': 'AntsLabor是一款智能房产管理应用程序，可通过便捷的方法，实现对房产物业的高效管理。',
    'joinus': '免费加入',
    'signup_free': '免费获取账号',
    'p1': '绑定你的水电气账户,',
    'p2': '全面监控你的使用量。',
    'tryout': '免费试用',
    'demop': '创建水电气图表，智能管理你的用度账单',
    'benefits': {
      'title': '我们的平台让你的房屋管理更简单',
      'subtitle': '我们的一站式平台为你覆盖一系列的房屋管理，包含水电管理，安全预警，租客管理，财务及税务管理等。',
    },
    'saving': '更节省',
    'savingp1': '智能管理你的用度账单以节省时间。',
    'savingp2': ' 预防不必要的漏水漏电以节约支出。',
    'secure': '更安全',
    'securep1': '预设意外防护管理来保障你的房屋安全。',
    'securep2': '突发情况时帮助你一键联系紧急联络人。',
    'simple': '更简易',
    'simplep1': '自动化你的租客管理，会计及税务流程。',
    'simplep2': '摆脱繁重的纸质工作和人为失误。',
    'smart': '更智能',
    'smartp1': '为你整合零碎的数据并进行安全存储。',
    'smartp2': ' 运用数据智能更好的监管你的房屋。',
    'bottom': {
      'message_1': 'Start managing your space smartly.',
      'message_2': 'No payment information required.',
      'free_trial': '免费试用'
    },
    'utility-monitoring': {
      'title': '水电用度监控',
      'subtitle': '工乙科技跟踪水电用度账单，并助您通过申报能源税收抵免来省钱',
      'p': '让电脑来帮你照看用度的使用情况以及防止各种漏水，漏电，欠款以达到节省支出。水，电，燃气，网络，等等。管理各类用度账单一直都这么索然无味，却又势在必行。干嘛不用个工具呢？深度分析同比参照，提高能源使用率，环保又省钱！烦人的事情就交给电脑吧，这样我们才能更好地享受美好时光。',
      'sub_feature_1': {
        'title': '跟踪更简易',
        'content': '囊括不同的用度账单包括水，电，气和wifi等等于一份简单易懂的彩色图表，实时监控您的水电费用'
      },
      'sub_feature_2': {
        'title': '即时警报',
        'content': '立即得知漏水，停电和其他公共设施的紧急情况'
      },
      'sub_feature_3': {
        'title': '节省能源成本',
        'content': '帮助您获得高达1247加元的安大略省能源税收抵免'
      }
    },
    'emergency-precaution': {
      'title': '意外防护',
      'subtitle': '工乙科技帮助管理安全预防指引和紧急处理手册，以确保物业财产安全',
      'p': '我们平台提供房屋相关的各类防护措施和相关信息。比如合理地安装烟雾报警器对于防火这件事以及保证你的安全是至关重要的。有些宝贵的东西，在危难来临时，即使是消防局的火警英雄们也无法挽救。多元文化是我们的优势。新移民少数族裔家庭的消防措施也很重要。',
      'sub_feature_1': {
        'title': '预防措施',
        'content': '咨询烟雾警报和消防法规指南，以帮助确保物业安全'
      },
      'sub_feature_2': {
        'title': '一键式紧急联络簿',
        'content': '即时访问物业维护和紧急联系信息'
      },
      'sub_feature_3': {
        'title': 'Online User Manual',
        'content': 'Digital online manual with built-in translation for newcomers'
      }
    },
    'rental-management': {
      'title': '物业租赁管理',
      'subtitle': '工乙科技将您的物业租赁管理效率提高了10倍。使工作变得更简单，更智能。',
      'p': '自动化的会计系统会处理好定期的租金收款并发送收据。智能科技提供毫无压力的租客管理系统。电子版的入住合同减少空房期让你对业务更自信。 信用分数评估报表帮助你更简洁高效地筛选租客。全方位无差别的物业管理体验让你不受地理局限，可以随时开始一场说走就走的旅行。',
      'sub_feature_1': {
        'title': '控制更直观',
        'content': '通过发送电子邮件数字化租金收据和租金到期提醒，来跟踪和管理租客及时付款'
      },
      'sub_feature_2': {
        'title': '即时数据存储',
        'content': '实时存储您的租金和物业财务数据于我们的安全数据库'
      },
      'sub_feature_3': {
        'title': '智能数据可视化',
        'content': '一键式可视化您所有的物业数据和租金统计数据的智能分析结果。'
      },
      'sub_feature_4': {
        'title': 'Online Lease Signing',
        'content': 'Use Ontario Standard Lease template to create your tenancy agreements quickly.'
      }
    },
    'property-data-intelligence': {
      'title': '物业数据智能',
      'subtitle': '工乙科技每月为您节省2小时以上来处理用度账单，并提供智能的物业财务绩效分析',
      'sub_feature_1': {
        'title': '实时记账',
        'content': '无忧自动费用记账，为您每月节省2个小时以上'
      },
      'sub_feature_2': {
        'title': '智能分析',
        'content': '轻松收集和分析租金收入，水电用度费用，维护保养成本，及物业税等'
      },
      'sub_feature_3': {
        'title': '退税申请',
        'content': '跟踪可选的退税项目并轻松生成税表以帮助优化财产收入'
      }
    },
    'claim-tax-refunds': {
      'title': '退税申请',
      'subtitle': '自动整理税务 预估退款金额',
      'p': '让我们来解决烦人的财务和会计。会计报表，税务返还，以及维护收据。无缝的支出记录和各类退税列表帮助你极致优化投资回报。自动化的个人财务信息处理，结合物业金融计算能力。无论你是需要照料一个家还是管理100个物业，工乙科技都能提供便捷的管理和维护。',
    },
  },
  'about-us': {
    'title': '关于我们',
    'brand': {
      'title': '我们的品牌',
      'tag': '让数据为生活服务',
      'description': '工艺源于匠心，工蚁精诚合作。永无止尽的兀，生生不息的创新。',
      'part1': '工',
      'part1-pronounciation': '',
      'part1-meaning': '努力而务实',
      'part2': '乙',
      'part2-pronounciation': '',
      'part2-meaning': '谦卑且精湛',
    },
    'story': {
      'title': '品牌故事',
      'text': '科技创新通常来源于生活。工乙的故事起源于我们的创始人-秋季节-某天整理用度账单时，意外发现过去的3个月里，他的水费账单是平时的10倍。之后，他开始寻找原因，发现是因为房屋隐秘的角落漏水导致了用水过度。而这些，供水公司并无义务及责任及时告知用户。因为没有及时核对账单，秋季节足足经历了3个月的用水浪费。<br><br>作为屋主，他需要花费太多时间去管理他的住处，包括繁杂的用度账单，冗长的税务文件，保证居住安全，日常维护和租赁管理等等。所有的这些琐事令他陷入思考：为什么我们不能更好的管理用度账单和税务文件呢？如何才能保护所有人在租房过程中应有的权益呢？ 为什么不自动化这些琐事来更快更准确地完成这些工作呢？我们需要一个解决方案！<br><br>于是聚集了我们，一个致力于改善人们居住生活的团队。',
      'summary': '我们把这个项目叫做工乙。'
    },
    'business': {
      'title': '使命愿景',
      'text': '我们利用大数据和人工智能技术使房屋管理自动化。我们通过IoT为下一代智能住宅和绿色科技探索可持续的建筑材料和世界一流的设计。 <br><br> 我们旨在通过我们的平台，让你更好地监控和管理你的房屋，提高运营效率并优化财务状况，来解决棘手的住房问题。',
      'summary': '让机器去做它们最高效的事情，人们就可以更轻松地生活!'
    },
    'team': {
      'title': '团队介绍',
      'text': '我们的成员大都是千禧一代的专业人士，对于利用数字化家庭管理来提供更好的居住体验非常感兴趣。'
    }
  },
  'payment': {
    'paypal': 'PayPal',
    'stripe': 'Stripe',
  },
  'support-us': {
    'title': 'Support Us',
    'question': {
      'header-p1': 'LIKE OUR PRODUCT',
      'header-p2': 'LET US KNOW',
      'welcome': "Glad to see you here! We're doing our best to bring you cutting- edge features that make property management a breeze.Would you help us out by answering these questions?",
      'save-p1': 'How many',
      'save-p2': 'monthly can you save with our services?',
      'pricing': 'How many cups of ☕ are you willing to exchange for our service per month?',
    },
    'features': {
      'tenant': 'Tenancy Management',
      'reminder': 'Maintenance Reminder',
      'utility': 'Utility Usage Oversight',
      'finance': 'Organize Finance Data',
    },
    'coffee': {
      'five': '5 cups',
      'multiple': '4 or 3 or 2 cups ',
      'one': '1 cup'
    },
    'modal': {
      'header': 'More Coming Soon!',
      'body': 'Thank you for your continued interest in AntsLabor. We are dedicated to building features that are valuable to our landlords and homeowners community. Watch this space for our feature ready notification...',
      'footer': 'Leave your email here to get updated'
    },
    'donate': {
      'title': '我们需要您的帮助',
      'desc': '在您的不断鼓励下，我们将更好地改善我们的产品并为您提供最好的用户体验',
      'donate_stripe': '使用Stripe捐赠',
      'donate_paypal': '使用Paypal捐赠',
    }
  },
  'dashboard': {
    'modal': {
      'body_create_property': '请您填写新物业的地址。'
    },
    'expandable': {
      'menu': '菜单 ▼',
      'change_password': '更换密码 ▼',
    },
    'map': '一览图',
    'yesterday': '昨天',
    'today': '今天',
    'tomorrow': '明天',
    'todo': '计划中',
    'started': '正在进行',
    'done': '已完成',
    'priority': '优先事项'
  },
  'profile': {
    'modal': {
      'body_prompt': '暂时没有物业信息。<br>你可以点击<button type="button" class="btn btn-success btn-sm">&#10010;</button>添加新的物业。',
      'body_create_property': '刚注册了一个新的物业。<br>你可以点击<button type="button" class="btn btn-outline-primary btn-sm">&#9997;</button>填写更多物业信息。'
    },
    'expandable': {
      'contact': '联系方式 ▲',
      'memo': '备忘录 ▼'
    },
    'edit': '编辑资料 ✍',
    'email': '帐号邮箱',
    'name': '展示名称',
    'phone': '电话号码',
    'language': '语言',
    'address': '地址',
    'city': '城市',
    'province': '省份',
    'postal_code': '邮编号码',
    'nationality': '国籍',
    'passport': '护照',
    'health_card': '健康卡',
    'driver_licence': '驾照',
    'car_plate': '车牌',
    'aeroplan': '里程卡',
    'library_card': '借书证'
  },
  'property': {
    'modal': {
      'body_prompt': '这是一个空的物业。<br>你可以点击<button type="button" class="btn btn-success btn-sm">&#10010;</button>添加新的住户。',
      'body_create_tenant': '刚注册了一个新的住户。<br>你可以点击<button type="button" class="btn btn-outline-primary btn-sm">&#9997;</button>填写更多住户信息。'
    },
    'breadcrumb': {
      'accounting': '会计税务',
      'precaution': '预防措施',
      'expense': '用度花费',
      'finance': '金融贷款'
    },
    'expandable': {
      'tenants': '全屋租客 ▼',
      'address': '具体地址 ▼',
      'mortgage': '房贷信息 ▼',
      'precaution': '消防措施 ▼',
      'emergency': '紧急联络 ▼',
      'data-sync-config': '帐号设置 ⚙️',
      'utility_details': '用度消费报表 ▼',
      'download_utility': '下载 CSV ⬇️',
      'monthly_expense': '每月详细支出 ▲',
      'yearly_expense': '每年详细支出 ▼',
      'utility_chart': '每月支出览图 ▲',
      'condition': '物业概况 ▼',
      'oeptc': '安省能源及地税补贴 ▲',
      't776': '出租物业相关文件 ▲',
      'balance_sheet': '资产负债表 ▼',
      'electricity_account': '⚡ 电费',
      'water_account': '💧 水费',
      'internet_account': '📡 网费',
      'boiler_account': '🔥 热水炉',
      'gas_account': '💨 天然气',
      'public_service_account': '公共服务(地税) ▼',
      'insurance_account': '房屋保险费 ▼'
    },
    'new': '✚ 新物业',
    'address': '地址',
    'street_number': '街道号码',
    'street_name': '街道名',
    'city': '城市',
    'postal_code': '邮编',
    'suite': '房间号',
    'move_in_date': '入住日期',
    'owner': '业主',
    'occupancy': '入住人数',
    'vacancy': '空房间',
    'monthly_expense': '月度支出',
    'annual_expense': '年度支出',
    'income': '租金收入',
    'mortgage_expandable': {
      'appraisal': '估值',
      'appreciation': '增值',
      'price': '购买价格',
      'down_payment': '首付',
      'mortgage_principal': '房贷本金',
      'amortization': '偿还时限(年)',
      'remaining_amortization': '剩余周期(月)',
      'interest_rate': '利率 (%)',
      'remaining_principal': '未还本金',
      'mortgage_payment': '房贷供款',
      'mortgage_interest': '房贷利息',
      'principal_repayment': '本金还款',

      'rent': '每月租金收款',
      'monthly_expense': '每月总共支出',
      'yearly_expense': '每年固定支出',
      'rental_gain_or_lose': '每月出租盈亏',
      'mortgage_gain_or_lose': '每月贷款盈亏',
      'gain_or_lose': '盈亏',
      'appreciation_per_month': '每月房价增值',
      'roi': '回报率 (%/年)'
    },
    'expense_expandable': {
      'utility_cost': '费用',
      'utility_provider': '供应商',
      'utility_contacts': '联系方式',
      'utility_username': '账号',
      'utility_password': '密码',
      'sync': '同步这个用度所消耗的金额。',
      'updated_on': '更新于: ',

      'electricity': '供电',
      'water': '供水费',
      'internet': '网络',
      'boiler': '热水炉',
      'gas': '天然气费',

      'maintenance': '维护',
      'gardening': '园艺',
      'lawning': '除草',
      'snow': '除雪',

      'renovation': '装修以及拓展',
      'depreciation': '家具家电老化',
      'terminal_loss': '部件永久破损',
    },
    'condition_expandable': {
      'size': '占地面积 (平尺)',
      'year': '建造年份',
      'roof': '屋顶架构',
      'exterior': '外延材料',
      'driveway': '车道空位',
      'bedroom': '卧室',
      'bathroom': '洗浴间',
      'kitchen': '厨房',
      'stairs': '楼道',
    },
    'add_property': '新增物业',
    'property_detail': '物业详情'
  },
  'tenant': {
    'expandable': {
      'contact': '联系方式 ▼',
      'payment': '房租付款 ▲',
      'habit': '卫生习惯 ▼'
    },
    'rent_receipt': '房租收据',
    'list_of_receipts': '收据历史总览',
    'receipt_qrcode': '扫描以下二维码，获取这张收据的在线备份。',
    'agreement_qrcode': '扫描以下二维码，获取这份合同的在线备份。',
    'check_out_confirmation': '确认退房',
    'room_number': '房间号',
    'address': '租凭地址',
    'name': '住户姓名',
    'record': '收据记录',
    'student_id': '学生证号码',
    'passport': '护照',
    'driver_licence': '驾照',
    'phone': '电话号码',
    'email': '电子邮箱',
    'facebook': 'Facebook',
    'wechat': '微信',
    'emergency_contact': '紧急联系人',
    'rental_start_date': '租约起始日期',
    'rental_end_date': '租约结束日期',
    'rent': '租金数额',
    'currency': '货币',
    'cycle': '租约期限',
    'payment_method': '支付方式',
    'deposit': '押金',
    'balance': '月结',
    'paid': '总共已支付金额',
    'owing': '亏欠余额',
    'language': '语言',
    'late_payment_tally': '付款延迟日期',
    'pets': '宠物',
    'drinking': '酗酒',
    'smoking': '吸烟',
    'marijuana': '大麻',
    'delete_confirm': 'You are going to Delete this property permanently. Do you confirm to do it?',
    'archive_or_delete': 'You have the lease and rent data saved in this property. If you delete it, you will lose your data permanently. You can choose Archive to keep your data safe in your personal folder. Please confirm which action you want to take.',
    'tenant_email': 'Tenant Email',
    'tenant_lang': 'Tenant Language',
    'tenant_addr': 'Tenant Address',
    'tenant_start': 'Tenant Start Date',
    'tenant_end': 'Tenant End Date',
    'tenant_phone': 'Tenant Phone Number',
    'tenant_rent': 'Monthly Rent',
    'property': 'Property',
    'status': 'Status',
    'due_date': '结款日期',
    'of_month': '号，每月',
    'payment': '付款结算',
    'document': '记录日期',
    'receipt_issued': '收据已寄出',
    'full_amount': '全额',
    'other_amount': '部分',
    'delay_tooltip': 'The rent payment has been delayed. Click to confirm the payment',
    'remind': '提醒',
    'remind_tooltip': 'Only one reminder per day is allowed.',
    'reminder_sent': '提醒已发送',
    'payment_modal': {
      'header': 'Payment Confirmation',
      'enter_amount': 'Please confirm the total amount you have received',
      'confirm': '已收到 ?',
      'postponed': '延迟了',
      'cancel': '取消',
      'do_you_confirm': {
        '1': 'Do you confirm that the rent of $',
        '2': 'has been paid by',
        '3': 'for',
        '4': 'and ready to send out the receipt?',
      }
    },
    'balance_paid': '已结清',
    'other_amount_confirm': 'Please enter the Amount you have received from Tenant',
    'delay': '延期',
    'reminder_mail_sent': 'You have sent a payment reminder email to',
    'add_tenant': '✚ 新增租客',
    'new_vacancy': '✚ 空置房间',
    "tenant_detail": '租客详情',
    "receipt_history": '收入记录',
    "receipt": {
      "receipt": '收据',
      "date": '日期',
      "amount": '金额',
      "deposit": '押金',
      "cycle": '租约期限',
    },
    "overdue": '逾期',
    "rating": "评分"
  },
  'utility': {
    'headline': '想看这样的数据吗？在下面同步您的帐户以检查您自己的数据！',
    'monthly_expense': '每月总共支出',
    'number_of_tenants': '租客人数',
    'monthly_expense_each_tenant': '单个租客用度',
    'deposit': '预付押金(待退还)',
    'reminder': '提醒',
    'due_date': '规定日期',
    'countdown1': '倒计时',
    'days': '天',
    'countdown2': '到下次缴费时间',
  },
  'application': {
    'expandable': {
      'credit_score': '信用分数测试 ▼',
      'details': '合约细节 ▼'
    },
    'title': '入住申请表格'
  },
  'balance_sheet': {
    'title': '资债平衡表',
    'assets': '资产',
    'liabilities': '负债',
    'equity': '权值',
    'current_assets': '现有资产',
    'fixed_assets': '固定资产',
    'total_assets': '总资产',
    'current_liabilities': '现有负债',
    'long_term_liabilities': '长期负债',
    'total_liabilities': '总负债',
    'total_equity': '总权值'
  },
  'oeptc': {
    'title': 'OEPTC - 安省能源及地税补贴',
    'partA': 'A 部分 - 主要住址的支出',
    'line_61120_text': '主要住址的地税',
    'line_61210_text': '主要住址的能源支出',
    'partB': 'B 部分 – 报单',
    'declaration': '我申报以下关于我的主要住址的信息: ',
    'address': '地址',
    'postal': '邮编',
    'months': '总共居住月份',
    'amount': '支出数额',
    'is_long_term_care': '是否为老弱康护房？',
    'municipality': '区政府名字'
  },
  't776': {
    'title': 'T776 - 出租物业相关文件',
    'part3': '3. 收入',
    'line_8141_text': '全年租金毛收益',
    'line_8230_text': '全年其他收益',
    'line_8299_text': '将此行输入"所得税退税(T1)"第160行',
    'total_gross_income': '总的毛收益',
    'part4': '4. 各项支出',
    'line_8521_text': '广告费',
    'line_8690_text': '保险费',
    'line_8710_text': '利息以及银行费用',
    'line_8810_text': '办公室用度',
    'line_8860_text': '顾问费 (法务以及会计)',
    'line_8871_text': '管理或行政费用',
    'line_8960_text': '修理或维护费用',
    'line_9060_text': '工资，薪水，和福利 (包括红包)',
    'line_9180_text': '地产税',
    'line_9200_text': '交通费用',
    'line_9220_text': '水电费用',
    'line_9281_text': '机车费用 (不包括贬值)',
    'line_9270_text': '其他费用',
    'total_expenses': '总共费用',
    'line_9949_text': '私人部分的费用',
    '7': '7号金额',
    'amount_7_text': '[总共费用 - 第9949行] 可免除的费用',
    'line_9369_text': '[第8299行 - 7号金额] 调准前的净收入 (或支出)',
    'subtotal': '小记',
    '9': '9号金额',
    'amount_9_text': '共有者名下的其他费用, 即第9369行净收入的私人部分.',
    'line_9945_text': '你作为共有者时的其他可以免除的费用',
    '11': '11号金额',
    'amount_11_text': '[9号金额 - 第9945行]',
    'line_9947_text': '回收的资产折旧津贴',
    '13': '13号金额',
    'amount_13_text': '[11号金额 - 第9947行]',
    'line_9948_text': '终结性损失, 贬值资产',
    '15': '15号金额',
    'amount_15_text': '[13号金额 - 第9948行]',
    'line_9936_text': '总的资产折旧津贴',
    'line_9946_text': '[15号金额 - 第9936行] 将此行输入"所得税退税(T1)"第126行',
    'net_income': '净收入 (或损失)'
  },
  'quotes': {
    '1': '用上工具，不亦乐乎？'
    // '1': '物业管理的百宝箱。',
    // '2': '让你的庄园更加智能可靠。',
    // '3': '像改造城堡一样升级你的居所。',
    // '4': '为你的物业在网络上量身定做一个数字克隆。',
    // '5': '你知道其实是工蚁们种出了延龄草(安省标志)吗?',
    // '6': '为全人类文明助力!',
    // '7': '为个体赋能, 让每个人超神!',
    // '8': '让所有德才兼备的人们得到机会和尊重。',
    // '9': '多样性是我们的优势。'
  },
  'footer': {
    'canadian': '🇨🇦',
    'copyright': '版权所有',
    'twitter': '推特',
    'linkedin': '领英',
    'facebook': '脸书',
    'wechat': '微信',
    'email': '电子邮件'
  },
  'contact': {
    'title': '联系我们',
    'address': {
      '1': '<span>加拿大安大略省大多伦多地区万锦市<br>51 Pettigrew Court, L3S 1K4</span>',
      '2': '<span>加拿大安大略省南温莎埃塞克斯郡拉萨尔镇<br>6181 Disputed Road, N9H 0C1</span>'
    },
    'phone': '+1 (416) 938-0314',
    'email': '<a href="mailto:hello@antslabor.com?Subject=AntsLabor" target="_top">hello@AntsLabor.com</a>',
    'wechat': ' AntsTechnology ',
    'book': ' 预约在线会面 ',
    'meeting': '<a href="https://calendly.com/antslabor/jake-queue"> 点击这里预约在线会面 </a>'
  },
  'forgot_password': {
    'message': '用于设置密码的链接已经发送。<br>请查看你的电子邮箱。',
    'email_subject': '工乙科技 - 密码重置',
    'link_text': '点击此处链接以转至重置密码页面。'
  },
  'covid19': {
    'stay-home': "⚠️ 新冠疫情 – 你就别出门取邮件了。"
  },
  'features': {
    'utility': '同步各类用度 数据采集展示',
    'secure': '安全防护措施 灵活社区文化',
    'rental': '租客入住合同 租金收据打印',
    'accounting': '自动整理税务 预估退款金额',
    'mortgage': '计算物业贷款 房间信息分享'
  },
  'benefits': {
    'saves': {
      'title': '节省',
      'text1': '节约管理各类用度所花的时间。',
      'text2': '省去突发事件造成的用度浪费。'
    },
    'secure': {
      'title': '安全',
      'text1': '提供各种预防措施以保护房屋。',
      'text2': '一键开启各类紧急联系人信息。'
    },
    'simple': {
      'title': '简洁',
      'text1': '自动化租凭记录会计退税申请。',
      'text2': '免去人为因素造成的文件失误。'
    },
    'smart': {
      'title': '智能',
      'text1': '一站台加密整合全部相关数据。',
      'text2': '巧用商务智能来照看经营物业。'
    }
  },
  'letter': {
    'welcome': '欢迎使用工乙科技'
  },
  'fire_precaution': {
    'smoke_alarm': {
      'title': '烟雾报警器维护事项清单',
      'routine': '定期维护检测',
      'annual': '逐年维护检测',
      'text1': '安装稳妥；报警器被安全地固定在可见的墙上或天花板上。',
      'text2': '干净整洁；报警器的表面没有堆积油污和尘土，气孔没有被异物堵塞。',
      'text3': '运作正常；报警器会对检测仪器(或者烟雾)发出警报信号。',
      'text4': '报警器的驱动能源来自',
      'expiration': '该能源会在这个日期之后过期',
      'text5': '对于由电池驱动的报警器',
      'replaced': '电池供电良好。要不然，请更换电池。',
      'connected': '电池接触正常，两端衔接得很好。',
      'corrosion': '电池尾部没有腐蚀痕迹，没有流出化学物质的迹象。',
      'ac_wiring': '家用交流电',
      'std_battery': '常用电池',
      'll_battery': '长效电池'
    },
    'carbon_monoxide': {

    }
  },
  'matrix': {
    'user_count': '用户数量',
    'property_count': '物业数量',
    'tenant_count': '租客数量',
    'agreement_count': '合约数量',
    'pages_count': '节省纸张',
    'trees_count': ' 棵树木已被挽救 🌳🌲',
  },
  'emergency_contact': {
    'title': '紧急联系',
    'number': '联系号码',
    'police': '警察',
    'fire': '火警',
    'esa': '电局',
    'ambulance': '急救',
    'gas': '燃气',
    'plumber': '水管工',
    'water': '供水',
    'security': '安保/警报',
    'internet': '电话/网络'
  },

  'lease': {
    'ok': 'OK',
    'or': '或者',
    'title': '民居租约合同',
    'note': '⚠️ 注意: ',
    'header-note': 'This tenancy agreement (or lease) is required for tenancies entered into on April 30, 2018 or later. It does not apply to care homes, sites in mobile home parks and land lease communities, most social housing, certain other special tenancies or co-operative housing (see Part A of General Information). Residential tenancies in Ontario are governed by the Residential Tenancies Act, 2006. This agreement cannot take away a right or responsibility under the Residential Tenancies Act, 2006. Under the Ontario Human Rights Code, everyone has the right to equal treatment in housing without discrimination or harassment. All sections of this agreement are mandatory and cannot be changed.',
    'add': '添加',
    'save': '保存',
    'cancel': '撤销',
    'preview': '预览',
    'bar': {
      'parties': '合约双方',
      'occupants': '入住名单',
      'premises-1':'物业以及',
      'premises-2': '日常管理',
      'documents-1': '通知方式',
      'documents-2': '接收对象',
      'term-1': '生效日期',
      'term-2': '租期时长',
      'key': '换锁押金',
      'smoking': '吸烟限制',
      'insurance-1': "租客财产",
      'insurance-2': "保险要求",
      'rent': "租金",
      'responsibility-1': "租客承担",
      'responsibility-2': "的责任",
      'deposit': "押金",
      'inspection': "验房报告",
      'miscelaneous': "其余杂项",
      'signatures': "签字",
    },
    'form': {
      'name': 'Name',
      'address': 'Address',
      'city': 'City',
      'province': 'Province',
      'postal-code': 'Postal Code',
      'email': 'Email',
      'cell': 'Cell',
      'street': 'Street',
    },
    'parties': {
      'title': '合约双方',
      'header-one': 'this agreement is made duplicate between',
      'landlord': 'LANDLORD',
      'tenant': 'TENANT',
    },
    'occupants': {
      'title': '入住名单',
      'header-one': 'Name all adults and/or children who will occupy the premises:',
      'property-type': 'Type of property',
      'note': 'Only tenants and occupants named may live in the premises without written consent of the landlord',
      
    },
    'premises': {
      'title': '物业以及日常管理',
      'header-one': 'The landlord will rent to the tenant and the tenant will rent from the landlord the following:',
      'header-two': 'Do you have an agent or property manager for this address?',
      'header-three': 'Do you have a superintendant for this address?',
      'property-type': 'Type of property',
      'note': 'Only tenants and occupants named may live in the premises without written consent of the landlord',
      'tenant-address': "Tenant's mailing address",
      'tenant-emergency': "Tenant Emergency Contact",
      'tenant-emergency-phone': "Tenant Emergency Phone Number(s)",
      
    },
    'docs': {
      'title': '通知方式和接收对象',
      'header-one': 'All Notices to Terminate or service of documents to the landlord shall be in writing and served in person, by registered mail, or by any other means authorized by the Landlord to:',
      'check-one': 'The landlord / owner',
      'check-two': 'The agent or property manager',
      'check-three': 'The superintendant at the above noted address',
    },
    'term': {
      'title': '生效日期和租期时长',
      'header-one': 'Select Lease Term',
      'fixed': 'FIXED TERM',
      'fixed-desc': 'The lease starts on a fixed date and will end on a fixed date.',
      'monthly': 'MONTH-TO-MONTH',
      'monthly-desc': "The lease starts on a fixed date but won't end on a fixed date. It continues each month until the landlord or the tenant gives further notice.",
      'lease-begins': 'Lease Begins On',
      'lease-ends': 'Lease Ends On',
      'auto': ' Automatically goes on Month-to-Month on the end of the lease.',
    },
    'key': {
      'title': '换锁押金',
      'not-required': 'A key deposit is not required',
      'desc-one': 'The tenant will pay a refundable key deposit of',
      'desc-two': 'to recover the cost of replacing the keys, remote entrydevices or cards if they are not returned to the landlord at the end of the tenancy.',
      'desc-three': 'If a refundable key deposit is required, provide description and number of keys, access cards and remote entry devices.',
      'note': 'The key deposit cannot be more than the expected replacement cost. See Part H in General Information.',
    },
    'smoking': {
      'title': '吸烟限制',
      'header-one': 'Under provincial law, smoking is not allowed in any indoor common areas of the building. The tenant agrees to these additional rules on smoking:',
      'rules-desc': 'Provide description of smoking rules',
      'none': 'None',
      'note': 'In making and enforcing smoking rules, the landlord must follow the Ontario Human Rights Code. See Parts M and S in General Information.',
    },
    'insurance': {
      'title': "租客财产保险要求",
      'header-one': "Tenant's Insurance",
      'none': 'None',
      'required': 'The tenant must have liability insurance at all times. If the landlord asks for proof of coverage, the tenant must provide it. It is up to the tenant to get contents insurance if they want it.',
    },
    'rent': {
      'title': "租金",
      'header-one': "Tenant's Insurance",
      'info-a-one': 'a) Rent is to be paid on the ',
      'info-a-two': 'of each',
      'info-b-one': 'b) The tenant will pay the following rent:',
      'info-b-two': 'Base rent for the rental unit',
      'parking': 'Parking (if applicable)',
      'add': ' +  Add Service or Utility',
      'total': 'Total Rent (Lawful Rent)',
      'note-one': "2229 (2018/01) Page 3 of 13. This is the lawful rent for the unit, subject to any rent increases allowed under the Residential Tenancies Act, 2006. For example, the landlord and tenant may agree to a seasonal rent increase for additional services of air conditioning or a block heater plug-in. This amount does not include any rent discounts (see Section 7 and Part G in General Information).",
      'note-two': "The tenant cannot be required to pay rent by post-dated cheques or automatic payments, but can choose to do so.",
      'note-three': "The landlord's administration charge for an NSF cheque cannot be more than $20.00.",
      'info-c': 'c) Rent is payable to:',
      'info-d': 'd) Rent will be paid using the following methods:',
      'info-e-one': 'e) If the first rental period is a partial period, the tenant will pay a partial rent of',
      'info-e-two': 'on',
      'info-e-three': 'this partial rent covers the rental of the unit from',
      'info-e-four': 'to',
      'info-f-one': "f) If the tenant's cheque is returned because of non-sufficient-funds (NSF), the tenant will have to pay the landlord's administration charge of",
      'info-f-two': "plus any NSF charges made by the landlord's bank.",
      'cash': 'Cash',
      'cheque': 'Cheque',
      'other': 'Other',
    },
    'responsibility': {
      'title': "租客承担的责任",
      'header-one': "The tenant is responsible for the following (click all that apply):",
      'header-two': 'Provide details about services or list any services if needed (if necessary add additional pages)',
      'header-three': 'The following utilities are the responsibility of:',
      'landlord': 'Landlord',
      'tenant': 'Tenant',
      'electricity': 'Electricity:',
      'heat': 'Heat:',
      'water': 'Water:',
      'details': "If the tenant is responsible for the utilies, provide details of the arrangement, e.g. tenant sets up account with and pays the utility provider, tenant pays a portion of the utility costs",


    },
    'deposit': {
      'title': "押金",
      'header-one': "deposit of",
      'header-one-b': "(not to exceed one month's rent)",
      'rent-monthly': ' If the rent is collected monthly, one month’s rent can be collected as deposit and if the rent is paid weekly, one week’s rent can be collected at the beginning of the tenancy. The landlord can then pay the tenant 6 percent compound interest at the beginning of the next year and preceding years. This deposit can be put towards the last month’s rent.',
      'note-one': "Ontario sets provincial rent guidelines annually, published in August. Rent increase by landlords can only occur once every 12 months and 90 days written notice is required. These increases must abide by the annual provincial guidelines. If the landlord wishes to increase the rent beyond the guidelines, an application to the Ontario Rent Tribunal must be filed to obtain permission. ",
      'note-two': "This is not a security deposit or damage deposit as security and damage deposits are not permitted in Ontario.",
    },
    'inspect': {
      'title': "验房报告",
      'header-one': "An inspection of the premises and the preparation of a written inspection report signed by the landlord and tenant and prepared within 1 day of the start of the tenancy and within 1 day of the end of the tenancy is . If an inspection report is prepared, it shall form part of the agreement.",
    },
    'misc': {
      'title': "其余杂项",
      'header-one': 'The landlord and tenant promise to comply with the conditions set out in Schedule “A.” The tenant acknowledges receipt of the rules of the residential premises and residential property which are attached hereto as Schedule “___.”',
      'header-two': 'If the rent is unpaid one day after the due date, the landlord has the right to file an application to the Ontario Rent Tribunal for rent arrears. If the tenant does not pay the rent within two weeks, eviction can occur.',
      'header-three': 'All Notices to Terminate must be in writing. The tenant must give 60 days notice in writing to the landlord before the rent is due. If it is the landlord terminating the tenancy, depending on the situation, he or she must allow tenants the following notice periods:',
      'header-three-a': '14 days for non-payment of rent, 7 days notice for weekly or daily tenancy',
      'header-three-b': "2 months for landlord's use of property",
      'header-three-c': "20 days for cause or conduct",
      'header-four': "This agreement is for the benefit of and is binding on the landlord and tenant and their heirs, executors, administrators, and assigns.",
      'header-five': "Any or all tenants signing this lease take full responsibility for all terms and conditions.",
      'note-one': "If the tenant breaches conditions of the Ontario Rental Housing Tribunal, the landlord can apply to the Tribunal without giving the tenant notice.",
      'note-two': "A tenant may not end a fixed term lease until the end of the term. The landlord can only terminate the fixed term lease based on legitimate reasons in the legislation and cannot terminate solely based on the expiration of the fixed term agreement. If the tenant does not give notice to terminate at the end of the fixed term agreement, the lease automatically goes to month-to-month and the landlord cannot force the tenant to sign another fixed term lease. All conditions from previous lease will remain the same unless re-negotiated between the landlord and tenant. ",
    },
    'signatures': {
      'title': "签字",
      'header-one': 'Schedule "A"',
      'header-two': "The following conditions apply where the relationship of landlord and tenant exists, regardless of any agreement, declaration, lease, waiver, or other statement to the contrary:",
      'conditions': "CONDITIONS:",
      'header-three': '1. Sublet',
      'condition-a-one': 'The tenant may assign or sublet the residential premises to another person with the consent of the landlord.',
      'condition-a-two': 'A new tenant must assume all of the rights and duties under the existing tenancy agreement, at the same rent. ',
      'condition-a-three': 'The landlord must not charge a fee or receive a benefit, directly or indirectly, for giving this consent.',
      'condition-a-four': 'If a landlord unreasonably withholds consent to assign or sublet or charges a fee, the tenant may apply to the Ontario Rent Tribunal for arbitration.',
      'repairs': '1. Repairs',
      'landlords': '(a) Landlord’s Duties:',
      'condition-b-one': 'The landlord must provide and maintain the residential premises and residential property in a reasonable state of decoration and repair, making the residential ',
      'condition-b-two': 'premises and the residential property suitable for occupation by a reasonable tenant. The landlord must comply with health, safety, and housing standards required by law.',
      'condition-b-three': 'If the landlord is required to make a repair to comply with the above duties, the tenant may discuss it with the landlord. If the landlord refuses to make the repair,',
      'condition-b-four': 'the tenant may apply to the Ontario Rent Tribunal for the completion and costs of the repair.',
      'tenant': '(b) Tenant’s Duties:',
      'condition-c-one': 'The tenant must maintain ordinary health, cleanliness, and sanitary standards throughout the residential premises and residential property.',
      'condition-c-two': 'The tenant is not responsible for reasonable wear and tear to the residential premises.',
      'emergency': '(c) Emergency Repairs:',
      'condition-d-one': 'The landlord must post the name and telephone number of the designated contact person for emergency repairs.',
      'condition-d-two': 'The tenant must make at least two attempts to notify the person designated by the landlord,',
      'condition-d-three': 'and give a reasonable time for completion of the emergency repairs by the landlord.If the emergency repairs are still required,',
      'condition-d-four': 'the tenant may undertake the repairs and deduct the cost from the next month’s rent, provided a statement of account and receipts are given to the landlord.',
      'condition-d-five': 'The landlord may take over completion of the emergency repairs at any time',
      'condition-d-six': 'Emergency repairs must be urgent and necessary for the health and safety of persons or preservation of property and are limited to:',
      'condition-d-list-one':'major leaks in the pipes or roof,',
      'condition-d-list-two':'damaged or blocked water or sewer pipes or plumbing fixtures,',
      'condition-d-list-three': 'repairs to the primary heating system, and',
      'condition-d-list-four': 'defective locks that give access to the residential premises.',
      'locks': '3. Locks',
      'condition-e-one': 'Neither the tenant nor the landlord may change or add a lock or security device (for example, a door chain) to residential premises unless both agree,',
      'condition-e-two': 'or unless ordered by an arbitrator. In an emergency, the landlord may change the lock on the main door of the residential property and the tenant may',
      'condition-e-three': 'change a defective lock on the residential premises and promptly provide the other party with a copy of the new key.',
      'entry': '4. Entry of Residential Premises by the Landlord',
      'condition-f-one': 'The landlord may enter the residential premises only if one of the following applies:',
      'condition-f-two': 'the landlord gives the tenant a written notice which states why the landlord needs to enter the residential premises and specifies a reasonable time not sooner than 24 hours and not later than 72 hours from the time of giving notice; entry must only occur during daylight hours (8 a.m. to 8 p.m.)',
      'condition-f-three': 'there is an emergency;',
      'condition-f-four': 'the tenant gives the landlord permission to enter at the time of entry or not more than one month before the time of entry for a specific purpose',
      'condition-f-five': 'the tenant has abandoned the residential premises',
      'condition-f-six': 'the landlord has the order of an arbitrator or court saying the landlord may enter the residential premises;',
      'pets': '5. Pets and Smoking',
      'condition-g-one': 'If the tenancy agreement does not permit pets or smoking in the rental unit, the landlord has the right to give a Notice of Termination if the tenant has pets or smokes.',
      'condition-g-two': 'If the landlord permits pets on the premises but the pet(s) disrupt the peace and enjoyment of other tenants, the landlord has the right to file a Notice of Termination. ',
      'protection': '6. Application of the Tenant Protection Act',
      'condition-h-one': 'The terms of this tenancy agreement and any changes or additions to the terms may not contradict or change any right or duty under the Tenant Protection Act or a regulation made under the act and to the extent that a term of this tenancy agreement does contradict or change a right or duty under the Tenant Protection Act or a regulation made under that act the term of this tenancy agreement is void.',
      'condition-h-two': 'Any change or addition to this tenancy agreement must be agreed to in writing and initialed by both the landlord and tenant and must be reasonable. If a change is not agreed to in writing, is not initialed by the landlord and tenant, or is not reasonable it is not enforceable.',
      'arbitration': '7. Arbitration of Disputes',
      'condition-i-one': ' Despite any other provision of this tenancy agreement, under the Tenancy Protection Act a tenant has the right to apply for arbitration to resolve a dispute.',
      'additional': '8. Additional Terms'


    },
  },
}

